<script>
import AppModuleGuard from '@/mixins/ModulesGuards/AppModuleGuard'

export default {
	name: 'OAuthCallback',
	mixins: [AppModuleGuard],
	created: function () {
		const query = this.$route.query
		if (query.token && query.provider) {
			localStorage.setItem('OAuthProvider', query.provider)
			localStorage.setItem('token', query.token)
			// TODO : UPDATE THIS LINE ON NEW UX
			window.location.href = window.location.origin + '/home'
		} else if (query.ssoError) {
			this.appService.goTo({ name: 'sso-error', params: { vendor: query.vendor } });
		} else {
			this.appService.goTo('signin');
		}
	}
}
</script>
